import styles from "./StartiappLogo.module.css";

export default function StartiappLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 449.94 449.28"
      className={styles.logo}
    >
      <rect
        x="240.49"
        y="239.84"
        width="209.44"
        height="209.44"
        rx="61.45"
        ry="61.45"
      />
      <rect
        y="239.84"
        width="209.44"
        height="209.44"
        rx="61.45"
        ry="61.45"
      />
      <path d="M388.49,30.4c17.12,0,31.05,13.93,31.05,31.05v86.55c0,17.12-13.93,31.05-31.05,31.05h-86.55c-17.12,0-31.05-13.93-31.05-31.05V61.45c0-17.12,13.93-31.05,31.05-31.05h86.55M388.49,0h-86.55c-33.94,0-61.45,27.51-61.45,61.45v86.55c0,33.94,27.51,61.45,61.45,61.45h86.55c33.94,0,61.45-27.51,61.45-61.45V61.45c0-33.94-27.51-61.45-61.45-61.45h0Z" />
      <rect
        width="209.44"
        height="209.44"
        rx="61.45"
        ry="61.45"
      />
    </svg>
  );
}
